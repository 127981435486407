var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      model: {
        value: _vm.modalPreview,
        callback: function ($$v) {
          _vm.modalPreview = $$v
        },
        expression: "modalPreview",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "", color: "transparent" } },
        [
          _c(
            "v-app-bar",
            { attrs: { color: "transparent", fixed: "" } },
            [
              _c(
                "v-container",
                { staticClass: "d-flex align-center pa-0 pa-md-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.modalPreview = false
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text",
                          attrs: { "x-large": "" },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "d-flex justify-center transparent" },
            [
              _c("v-img", {
                attrs: {
                  "max-height": "500",
                  "max-width": "500",
                  src: _vm.foto,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }