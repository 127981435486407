var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.webcam,
              expression: "!webcam",
            },
          ],
        },
        [
          !_vm.confirmacaoDados
            ? _c(
                "v-bottom-sheet",
                {
                  attrs: { inset: "" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c("v-sheet", [
                    _c(
                      "div",
                      { staticClass: "my-3" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          ref: "inputImage",
                          attrs: {
                            accept: "image/png, image/jpeg, image/jpg",
                            type: "file",
                          },
                          on: { change: _vm.uploadImage },
                        }),
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.$refs.modalProfilePhoto.showPreview()
                                    _vm.dialog = false
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { tile: "" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "grey--text",
                                            attrs: { large: "" },
                                          },
                                          [_vm._v("mdi-image")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-list-item-title", [_vm._v(" Ver foto ")]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.$refs.inputImage.click()
                                    _vm.dialog = false
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { tile: "" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "primary--text",
                                            attrs: { large: "" },
                                          },
                                          [_vm._v("mdi-upload")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Escolher uma imagem "),
                                ]),
                              ],
                              1
                            ),
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? _c(
                                  "v-list-item",
                                  { on: { click: _vm.webcamOption } },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { size: "32px", tile: "" } },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "teal--text",
                                                attrs: { large: "" },
                                              },
                                              [_vm._v("mdi-webcam")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" Tirar foto "),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.foto,
                  expression: "foto",
                },
              ],
            },
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center flex-column" },
                  [
                    _c("v-hover", {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ hover }) {
                            return [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "border ml-6 mt-6",
                                  attrs: {
                                    color: "#ccc",
                                    src: "",
                                    size: "100",
                                    fab: "",
                                    absolute: "",
                                    bottom: "",
                                    left: "",
                                  },
                                  on: { click: _vm.handleCancelFile },
                                },
                                [
                                  _c(
                                    "v-img",
                                    {
                                      attrs: {
                                        src: _vm.foto,
                                        "aspect-ratio": 16 / 9,
                                      },
                                    },
                                    [
                                      _c("v-expand-transition", [
                                        hover
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex transition-fast-in-fast-out primary v-card--reveal caption white--text",
                                                staticStyle: { height: "100%" },
                                              },
                                              [_vm._v(" Clique aqui ")]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.webcam,
              expression: "webcam",
            },
          ],
        },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "400", "hide-overlay": "", persistent: "" },
              model: {
                value: _vm.modalWebcam,
                callback: function ($$v) {
                  _vm.modalWebcam = $$v
                },
                expression: "modalWebcam",
              },
            },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.fotoWebcam,
                        expression: "!fotoWebcam",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center flex-column" },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "mb-5 d-flex align-center",
                            attrs: { color: "grey", height: "300" },
                          },
                          [
                            _c("video", {
                              ref: "video",
                              staticClass: "video",
                              attrs: {
                                height: "300",
                                autoplay: "true",
                                id: "webCamera",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          { attrs: { color: "transparent" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          dark: "",
                                          color: "red",
                                          icon: "",
                                        },
                                        on: { click: _vm.cancelWebcam },
                                      },
                                      [
                                        _c("v-icon", { attrs: { large: "" } }, [
                                          _vm._v("mdi-close"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          loading: _vm.loading,
                                          icon: "",
                                          dark: "",
                                          color: "blue",
                                        },
                                        on: { click: _vm.takeSnapShot },
                                      },
                                      [
                                        _c("v-icon", { attrs: { large: "" } }, [
                                          _vm._v("mdi-camera"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c(
                    "v-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fotoWebcam,
                          expression: "fotoWebcam",
                        },
                      ],
                      attrs: { tile: "", color: "transparent" },
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "transparent pl-0 pt-0" },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "mb-5 d-block align-center",
                              attrs: {
                                color: "grey",
                                width: "400",
                                height: "300",
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.fotoWebcam,
                                  contain: "",
                                  height: "300",
                                  "max-width": "400",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { attrs: { color: "transparent" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "red--text",
                                          attrs: { large: "" },
                                          on: { click: _vm.handleCancelVideo },
                                        },
                                        [_vm._v("mdi-close-thick")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-left",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "green--text",
                                          attrs: { large: "" },
                                          on: { click: _vm.cancelWebcam },
                                        },
                                        [_vm._v("mdi-check-bold")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("display-profile-photo", {
        ref: "modalProfilePhoto",
        attrs: { foto: _vm.foto },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }