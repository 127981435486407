<template>
  <v-dialog v-model="modalPreview" max-width="500">
    <v-card tile color="transparent">
      <v-app-bar color="transparent" fixed>
        <v-container class="d-flex align-center pa-0 pa-md-4">
          <v-btn class="mr-3" icon @click="modalPreview = false">
            <v-icon x-large class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-container>
      </v-app-bar>
      <v-container class="d-flex justify-center transparent">
        <v-img max-height="500" max-width="500" :src="foto"></v-img>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    foto: String,
  },
  data() {
    return {
      modalPreview: false,
    };
  },
  methods: {
    showPreview() {
      this.modalPreview = true;
    },
  },
};

</script>

<style>
</style>
