<template>
  <div>
    <div v-show="!webcam">
      <v-bottom-sheet
        v-if="!confirmacaoDados"
        v-model="dialog"
        inset
      >
        <v-sheet>
          <div class="my-3">
            <input
              v-show="false"
              accept="image/png, image/jpeg, image/jpg"
              type="file"
              ref="inputImage"
              @change="uploadImage"
            />
            <v-list>
              <v-list-item @click="$refs.modalProfilePhoto.showPreview();dialog = false;">
                <v-list-item-avatar>
                  <v-avatar tile>
                    <v-icon large class="grey--text">mdi-image</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>
                  Ver foto
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="$refs.inputImage.click();dialog = false;">
                <v-list-item-avatar>
                  <v-avatar tile>
                    <v-icon large class="primary--text">mdi-upload</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>
                  Escolher uma imagem
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="$vuetify.breakpoint.mdAndUp" @click="webcamOption">
                <v-list-item-avatar>
                  <v-avatar size="32px" tile>
                    <v-icon large class="teal--text">mdi-webcam</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>
                  Tirar foto
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <v-row v-show="foto">
        <v-col>
          <div class="d-flex align-center flex-column">
            <v-hover v-slot="{ hover }">
              <v-avatar @click="handleCancelFile" color="#ccc" class="border ml-6 mt-6" src size="100" fab absolute bottom left>
                <v-img :src="foto" :aspect-ratio="16/9">
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal caption white--text"
                      style="height: 100%;"
                    >
                      Clique aqui
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-avatar>
            </v-hover>
          </div>
        </v-col>
      </v-row>
    </div>

    <div v-show="webcam">
      <v-dialog v-model="modalWebcam" width="400" hide-overlay persistent>
        <transition name="fade">
          <div v-show="!fotoWebcam">
            <div class="d-flex align-center flex-column">
              <v-sheet color="grey" class="mb-5 d-flex align-center" height="300">
                <video class="video" height="300" ref="video" autoplay="true" id="webCamera"></video>
              </v-sheet>
              <div color="transparent">
                <v-row>
                  <v-col cols="6">
                    <v-btn dark color="red" icon @click="cancelWebcam">
                      <v-icon large>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn :loading="loading" icon dark color="blue" @click="takeSnapShot">
                      <v-icon large>mdi-camera</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <v-card v-show="fotoWebcam" tile color="transparent">
            <v-container class="transparent pl-0 pt-0">
              <v-sheet color="grey" class="mb-5 d-block align-center" width="400" height="300">
                <v-img :src="fotoWebcam" contain height="300" max-width="400"></v-img>
              </v-sheet>
              <div color="transparent">
                <v-row>
                  <v-col cols="6" class="text-right">
                    <v-icon large class="red--text" @click="handleCancelVideo">mdi-close-thick</v-icon>
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <v-icon large class="green--text" @click="cancelWebcam">mdi-check-bold</v-icon>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card>
        </transition>
      </v-dialog>
    </div>

    <display-profile-photo
      ref="modalProfilePhoto"
      :foto="foto"
    />

  </div>
</template>

<script>
import DisplayProfilePhoto from './DisplayProfilePhoto.vue';

export default {
  components: {
    DisplayProfilePhoto,
  },
  props: {
    value: {
      required: true,
    },
    confirmacaoDados: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      foto: '',
      webcam: false,
      dialog: false,
      fotoWebcam: '',
      modalWebcam: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (!this.value) return;
        this.foto = this.value;
      },
    },
  },
  methods: {
    cancelWebcam() {
      this.stop();
      this.webcam = false;
      this.modalWebcam = false;
      if (this.fotoWebcam) {
        this.foto = this.fotoWebcam;
        this.$emit('input', this.fotoWebcam);
        this.$emit('salvarfoto');
        this.fotoWebcam = '';
      }
      // this.handleCancelVideo();
    },
    webcamOption() {
      this.dialog = false;
      this.modalWebcam = true;
      this.webcam = true;
      setTimeout(() => {
        this.loadCamera();
      }, 500);
    },
    async uploadImage(event) {
      if (event.target.files && event.target.files[0]) {
        const stream = await this.imageBase(event.target.files[0])
        this.$emit('input', stream);
        this.$emit('salvarfoto');
      }
    },
    async imageBase(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => resolve(reader.result);
      });
    },
    handleCancelFile() {
      // this.foto = '';
      // this.$refs.inputImage.value = '';
      // this.$emit('input', '');
      this.dialog = true;
    },
    handleCancelVideo() {
      this.fotoWebcam = '';
      // this.$emit('input', '');
      this.loadCamera();
    },
    takeSnapShot() {
      this.loading = true;
      // Captura elemento de vídeo
      const { video } = this.$refs;

      // Criando um canvas que vai guardar a imagem temporariamente
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');

      // Desenhando e convertendo as dimensões
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Criando o JPG
      const dataURI = canvas.toDataURL('image/jpeg'); // O resultado é um BASE64 de uma imagem.

      setTimeout(() => {
        this.loading = false;
        this.fotoWebcam = dataURI; // Gerar Imagem e Salvar Caminho no Banco
        this.stop();
        // this.$emit('input', dataURI);
      }, 500);
    },
    loadCamera() {
      const { video } = this.$refs;
      // As opções abaixo são necessárias para o funcionamento correto no iOS
      video.setAttribute('autoplay', '');
      video.setAttribute('muted', '');
      video.setAttribute('playsinline', '');

      // Verifica se o navegador pode capturar mídia
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { facingMode: 'user' } })
          .then((stream) => {
            // Definir o elemento vídeo a carregar o capturado pela webcam
            video.srcObject = stream;
          })
          .catch((error) => {
            this.$root.$snackBar.open({
              color: 'error',
              message: 'Não foi possível acessar à webcam',
            });
            this.cancelWebcam();
          });
      }
    },
    stop() {
      const { video } = this.$refs;
      video.pause();
      video.removeAttribute('src');
      video.load();
      video.srcObject = null;
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  position: absolute;
  display: none;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.video {
  display: block;
  height: 300px;
  width: 400px;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
  cursor: pointer;
}
</style>
